import React, { useEffect, useState } from "react"
import VideosList from "./VideosList/VideosListCommunity"
import { VideosGrid } from "../../queries/common_use_query"

import {GetVideos} from "../../queries/api_links"
import {
  Container,
  Row,
  Col
} from "react-bootstrap"
import "./Videos.scss"
import { navigate } from "gatsby"

// styles
// markup
const VideosCommunity = ( props ) => {

 // console.log("Videos list", props)

  const [video_items, setVideoItems] = useState([]);
  const [video_list, setVideoList] = useState(true);
  const [more_video, setMorevideo] = useState(0);
  //const [filterCat, setFilterCat] = useState('');
  //const [include_videos, setIncludeVideos] = useState([]);
  // console.log("@@@@", data+"---"+data);

 /* const filterCateogy = (playlist_id, include_video) =>{
    //console.log("category", category);
    setFilterCat(playlist_id);
    setVideoList(true);
    setVideoItems([]);
    setIncludeVideos(include_video);

    const youtube_videos = GetVideos(playlist_id).then(res =>{
      //console.log("youtube_videos", res);
      if(res.status === 200){
        setVideoItems(res.data.items);
        if(res.data.nextPageToken){
          nextPageVideos(playlist_id, res.data.nextPageToken, res.data.items);
        }       
      } else{
        setVideoItems([]);
        setVideoList(false)
      }
    });
  }*/

  useEffect(()=>{
    var playlist_id = props.Playlist_Videos;
    //setFilterCat(playlist_id.Playlist_ID);
    //setIncludeVideos(props.Modules.Playlist_Videos[0].Include_Video_ID);

    const youtube_videos = GetVideos(playlist_id).then(res =>{
      //console.log("youtube_videos", res);
      if(res.status === 200){
        let topTenItems = res.data.items.length > 10 ? res.data.items.slice(0, 10):res.data.items
        setVideoItems(topTenItems);
        setMorevideo(res.data.items.length > 10 ? 1:'');
       
        //if(res.data.nextPageToken){
         // nextPageVideos(playlist_id.Playlist_ID, res.data.nextPageToken, res.data.items);
       // }

        
      } else{
        setVideoItems([]);
        setVideoList(false)
      }
    });
  },[])

  

  /*const nextPageVideos = (playlist_id, nextPageToken, prevItems)=>{
    //var playlist_id = props.Modules.Playlist_Item[0];

    //console.log("playlist_id", playlist_id);

    const youtube_videos = GetVideos(playlist_id, nextPageToken).then(res =>{
      console.log("youtube_videos", res);
      var video_array = [];
      prevItems.length > 0 && prevItems.map((item)=>{
        video_array.push(item);
      })
      if(res.status === 200){
        res.data.items.length > 0 && res.data.items.map((item)=>{
          video_array.push(item);
          
        })
      } else{
        setVideoItems([]);
        setVideoList(false)
      }

      setVideoItems(video_array);
      
    });
  }*/


  const openSharefrndformModal = (e) => { 
      navigate('/about/videos/', { state: { slug: e} });
  }


  return (
    <React.Fragment>
      <div className={`filter-block- insights- video_filter ${more_video  ? '' : 'reduce_pad'}`}>
        <Container>
          <Row>
            <Col lg="12">
              <div className="filter-block-group video_filt_blk">

                  <div className="filter-link">
                 <h2>{props.Name} Property Videos</h2>
                  </div>
   
              </div>
            </Col>
          </Row>
        </Container>

        { video_items && 
          <VideosList data = {video_items} video_list={video_list} include_videos={""} filterCat={""} GQLModules={props.GQLModules} />
        }
        {more_video &&
          <div className="more_videos">
            <Container>
              <Row>
                <Col lg="12">
                  <a href="javascript:void(0)" onClick={() => openSharefrndformModal(props?.Playlist_Videos)}  class="btn btn-primary"><span>View More</span></a>
                </Col>
              </Row>
            </Container>
          </div>
        }
      </div>
    </React.Fragment>
  )
}

export default VideosCommunity
